import PropTypes from "prop-types";
import { css } from "@emotion/css";

const ScoreBar = ({ ha = 0, hb = 0 }) => {
  const style = {
    scoreBar: css`
      width: 100%;
      height: 5px;
      background: lightgreen;
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      &:before,
      &:after {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        background: #f00;
      }
      &:before {
        background: #ff9933;
        right: 0;
        width: ${`${hb}%`};
      }
      &:after {
        background: #ff33cc;
        right: ${`${hb}%`};
        width: ${`${ha}%`};
      }
      label: score-bar;
    `,
  };

  return <div className={style.scoreBar}></div>;
};

export default ScoreBar;

ScoreBar.propTypes = {
  ha: PropTypes.number,
  hb: PropTypes.number,
};
