import { css } from "@emotion/css";

const style = {
  mobileWarning: css`
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;
    font-size: 16px;
    label: mobile-warning;
  `,
};

const MobileWarning = () => {
  return (
    <div className={style.mobileWarning}>
      جهت نمایش بهتر از دسکتاپ استفاده کنید!
    </div>
  );
};

export default MobileWarning;
