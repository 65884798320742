import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { css } from "@emotion/css";
import api from "../../api";
import { mapHelper } from "../../helpers";
import { Map, Loading } from "../Shared";
import SimulatorResult from "./SimulatorResult";

const style = {
  simulatorContainer: css`
    .actions-container {
      margin-top: 16px;
      display: flex;
      justify-content: center;
    }
    label: simulator-container;
  `,
};

const Simulator = () => {
  const [loading, setLoading] = useState(false);
  const [trip, setTrip] = useState(null);
  const rTrip = useRef(null);
  rTrip.current = trip;

  const [selectedEvents, setSelectedEvents] = useState([]);
  const rSelectedEvents = useRef(null);
  rSelectedEvents.current = selectedEvents;
  const [simulatedTrip, setSimulatedTrip] = useState(null);

  useEffect(() => {
    setLoading(true);
    api
      .getRoute()
      .then((res) => {
        setTrip({ points: res });
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  }, []);

  const tripStartTime =
    rTrip.current && rTrip.current?.points[0]
      ? rTrip.current.points[0].timestamp
      : null;

  const handleSimulate = () => {
    setLoading(true);
    api
      .simulate(selectedEvents)
      .then((res) => {
        setSimulatedTrip(res);
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={style.simulatorContainer}>
      <Map
        trip={trip}
        events={rSelectedEvents.current}
        handleAddEvent={({ point, eventType }) =>
          setSelectedEvents([
            ...rSelectedEvents.current,
            {
              meta: {
                lat: point[0],
                lng: point[1],
              },
              event_type: eventType,
              second: mapHelper.getTimestampDifference(point[2], tripStartTime),
            },
          ])
        }
        handleRemoveEvent={(event) =>
          setSelectedEvents(
            rSelectedEvents.current.filter(function (obj) {
              return obj.second !== event.second;
            })
          )
        }
      />
      <div className="actions-container">
        <button onClick={handleSimulate} disabled={!selectedEvents.length}>
          شبیه‌سازی سفر
        </button>
      </div>
      <SimulatorResult
        trip={simulatedTrip}
        handleBack={() => setSimulatedTrip(null)}
      />
      {loading && <Loading />}
    </div>
  );
};

export default Simulator;
