import { useState, useRef, useEffect } from "react";
import { toast } from "react-hot-toast";
import { css } from "@emotion/css";
import api from "../../api";
import { mapHelper } from "../../helpers";
import { Map } from "../Shared";
import ProcessorForm from "./ProcessorForm";
import sampleTrip from "./sampleTrip.json";

const style = {
  processorContainer: css`
    .actions-container {
      display: flex;
      justify-content: center;
    }
    label: processor-container;
  `,
};

const Processor = () => {
  const [loading, setLoading] = useState(false);
  const [processedTrip, setProcessedTrip] = useState(null);
  const rProcessedTrip = useRef(null);
  rProcessedTrip.current = processedTrip;

  const handleProcess = (tripData) => {
    const validTripData = mapHelper.validateJson(tripData);
    if (validTripData) {
      setLoading(true);
      api
        .process(JSON.parse(tripData))
        .then((res) => {
          setProcessedTrip(res);
        })
        .catch((error) => {
          toast.error(
            error?.response?.status === 400
              ? "فیلدهای الزامی وارد نشده است"
              : error?.message
          );
        })
        .finally(() => setLoading(false));
    } else {
      toast.error("ساختار داده صحیح نیست");
    }
  };

  const tripStartTime =
    rProcessedTrip.current && rProcessedTrip.current?.points[0]
      ? rProcessedTrip.current.points[0].timestamp
      : null;

  const formatEvents = (events) => {
    return events?.map((event) => {
      return {
        event_type: mapHelper.getEventShortenType(event?.type),
        meta: {
          lat: event?.meta?.lat,
          lng: event?.meta?.lng,
        },
        second: mapHelper.getTimestampDifference(
          event?.meta?.timestamp,
          tripStartTime
        ),
      };
    });
  };

  useEffect(() => handleProcess(JSON.stringify(sampleTrip)), []);

  return (
    <div className={style.processorContainer}>
      <ProcessorForm
        handleProcess={(tripData) => handleProcess(tripData)}
        loading={loading}
      />
      <Map
        tripSummary={processedTrip}
        trip={{ points: processedTrip?.points }}
        events={formatEvents(processedTrip?.events)}
        hasSummary
      />
    </div>
  );
};

export default Processor;
