import { css } from "@emotion/css";

const style = {
  loading: css`
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 20%;
    }
    label: loading;
  `,
};

const Loading = () => {
  return (
    <div className={style.loading}>
      <img src="/loading.gif" alt="loading..." />
    </div>
  );
};

export default Loading;
