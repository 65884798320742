import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Main } from "./layouts";
import { Simulator, Processor, MobileWarning } from "./views";

function App() {
  const limitedMediaScreenWith = 480;
  const [matches, setMatches] = useState(
    window.matchMedia(`(min-width: ${limitedMediaScreenWith}px)`).matches
  );
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window
      .matchMedia(`(min-width: ${limitedMediaScreenWith}px)`)
      .addEventListener("change", handler);
  }, []);

  const urlParams = new URLSearchParams(window?.location?.search);
  const mode = urlParams.get("mode");

  return (
    <Main>
      {matches ? (
        <>
          {mode === "simulate" ? (
            <Simulator />
          ) : mode === "process" ? (
            <Processor />
          ) : (
            <></>
          )}
          <Toaster position="bottom-center" toastOptions={{ duration: 5000 }} />
        </>
      ) : (
        <MobileWarning />
      )}
    </Main>
  );
}

export default App;
