import React from "react";
import { css } from "@emotion/css";

const styles = {
  mainLayout: css`
    width: 100%;
    height: 800px;
    overflow: hidden;
  `,
};

const Main = ({ children }) => {
  return <div className={styles.mainLayout}>{children}</div>;
};

export default Main;
