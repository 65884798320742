import axios from "axios";
import jwtDecode from "jwt-decode";

const BASE_URL = "https://api.carbirdcloud.ir/v1";
const ACCESS_TOKEN = "ACCESS_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(ACCESS_TOKEN);
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const savedRefresh = localStorage.getItem(REFRESH_TOKEN);
      const decodedRefresh = savedRefresh ? jwtDecode(savedRefresh) : null;
      const access_token =
        savedRefresh && decodedRefresh.exp > new Date().getTime() / 1000
          ? await refreshToken(savedRefresh)
          : await getToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

const getToken = async () => {
  const responce = await axios
    .post(
      `${BASE_URL}/token/`,
      { username: "mohsen", password: "%i4i6C58CDeT" },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      localStorage.setItem(ACCESS_TOKEN, res?.data?.access);
      localStorage.setItem(REFRESH_TOKEN, res?.data?.refresh);
      return res?.data?.access;
    })
    .catch((err) => {
      throw err;
    });
  const data = await responce;
  return data;
};

const refreshToken = async (refresh) => {
  const responce = await axios
    .post(
      `${BASE_URL}/token/refresh/`,
      { refresh },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      localStorage.setItem(ACCESS_TOKEN, res.data.access);
      return res.data.access;
    })
    .catch((err) => {
      throw err;
    });
  const data = await responce;
  return data;
};

const getRoute = async () => {
  try {
    const res = await axios
      .get(`${BASE_URL}/route`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        return res;
      });
    const data = await res.data;
    return data;
  } catch (err) {
    throw err;
  }
};

const simulate = async (events) => {
  try {
    const res = await axios
      .post(
        `${BASE_URL}/simulate`,
        {
          events,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
    const data = await res.data;
    return data;
  } catch (err) {
    throw err;
  }
};

const process = async (trip) => {
  try {
    const res = await axios
      .post(
        `${BASE_URL}/process`,
        { body: trip },
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
    const data = await res.data;
    return data;
  } catch (err) {
    throw err;
  }
};

const api = { getRoute, simulate, process };

export default api;
