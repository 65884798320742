import { useState } from "react";
import { css } from "@emotion/css";
import { Loading } from "../Shared";

import sampleTrip from "./sampleTrip.json";

const style = {
  processorFormContainer: css`
    margin-bottom: 32px;
    textarea {
      font-family: FiraCode;
      height: 200px;
    }
    .actions-container {
      display: flex;
      justify-content: center;
    }
    label: processor-form-container;
  `,
};

const ProcessorForm = ({ handleProcess, loading }) => {
  const [tripValue, setTripValue] = useState(JSON.stringify(sampleTrip));

  return (
    <div className={style.processorFormContainer}>
      <textarea
        value={tripValue ? tripValue : ""}
        onChange={(e) => setTripValue(e.target.value)}
        placeholder={JSON.stringify(sampleTrip).substring(0, 652)}
      />
      <div className="actions-container">
        <button onClick={() => handleProcess(tripValue)} disabled={!tripValue}>
          پردازش
        </button>
      </div>
      {loading && <Loading />}
    </div>
  );
};

export default ProcessorForm;
