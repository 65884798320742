import { css } from "@emotion/css";
import { toast } from "react-hot-toast";
import ClipboardIcon from "../../assets/images/clipboard.svg";

const style = {
  simulatorResultContainer: css`
    margin-top: 32px;
    textarea {
      font-family: FiraCode;
      height: 115px;
    }
    .actions-container {
      display: flex;
      justify-content: center;

      .copy-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
    }
    label: simulator-result-container;
  `,
};

const SimulatorResult = ({ trip, handleBack }) => {
  return (
    <div className={style.simulatorResultContainer}>
      <textarea value={trip ? JSON.stringify(trip) : ""} readOnly />
      <div className="actions-container">
        <button
          className="copy-button"
          onClick={() =>
            navigator.clipboard
              .writeText(JSON.stringify(trip))
              .then(() => {
                toast.success("کپی شد!");
              })
              .catch(() => {
                toast.error("کپی با خطا مواجه شد!");
              })
          }
          disabled={!trip}
        >
          کپی دیتا
          <img src={ClipboardIcon} alt="کپی دیتا" />
        </button>
      </div>
    </div>
  );
};

export default SimulatorResult;
