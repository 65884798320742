import haIcon from "../assets/images/ha-icon.png";
import hbIcon from "../assets/images/hb-icon.png";
import ohIcon from "../assets/images/oh-icon.png";

const mapHelper = (function () {
  const getEventShortenType = (eventType) => {
    switch (eventType) {
      case "HA":
      case "harsh acceleration":
        return "HA";
      case "HB":
      case "harsh braking":
        return "HB";
      case "OH":
      case "overheating":
        return "OH";

      default:
        return "";
    }
  };

  const getEventTitle = (eventType) => {
    switch (eventType) {
      case "HA":
      case "harsh acceleration":
        return "شتاب ناگهانی";
      case "HB":
      case "harsh braking":
        return "ترمز ناگهانی";
      case "OH":
      case "overheating":
        return "جوش آوردن";

      default:
        return "";
    }
  };

  const eventMarker = ({ L, type, second, className = "", hasRemove }) => {
    let shortenType = getEventShortenType(type);

    return new L.divIcon({
      className: `custom-div-icon ${className}`,
      html:
        '<div class="custom-marker-pin ' +
        (hasRemove ? "has-close " : "") +
        shortenType +
        '-marker-pin">' +
        (hasRemove ? "<span>X</span>" : "") +
        // shortenType.toUpperCase() +
        "<img width=50 src=/" +
        shortenType.toUpperCase() +
        ".svg />" +
        "</div>",
      iconSize: [12, 12],
      iconAnchor: [15, 42],
    });
  };

  const startEndMarker = ({ L, type }) => {
    return new L.divIcon({
      className: `start-end-icon`,
      html: '<div class="start-end-pin custom-' + type + '-pin"/>',
      iconSize: [10, 10],
      iconAnchor: [10, 5],
    });
  };

  const routeLine = ({
    L,
    routePoints,
    color = "#2FC2F0",
    className = "",
    handleClickRoute,
    contextmenu = false,
  }) => {
    return new L.Polyline(routePoints, {
      color,
      weight: 7,
      opacity: 1,
      smoothFactor: 3,
      className,
      contextmenu: contextmenu,
      contextmenuInheritItems: false,
      contextmenuItems: [
        {
          text: "شتاب ناگهانی",
          callback: () =>
            handleClickRoute({ point: routePoints[0], eventType: "HA" }),
          icon: haIcon,
        },
        {
          text: "ترمز ناگهانی",
          callback: () =>
            handleClickRoute({ point: routePoints[0], eventType: "HB" }),
          icon: hbIcon,
        },
        {
          text: "جوش آوردن",
          callback: () =>
            handleClickRoute({ point: routePoints[0], eventType: "OH" }),
          icon: ohIcon,
        },
        "-",
        {
          text: "بستن",
        },
      ],
    });
  };

  const routeCurvedPath = ({
    L,
    start,
    end,
    offset = 5,
    color = "red",
    opacity = 0.9,
    className = "",
  }) => {
    var offsetX = end[1] - start[1],
      offsetY = end[0] - start[0];
    var r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2)),
      theta = Math.atan2(offsetY, offsetX);
    var thetaOffset = 3.14 / offset;
    var r2 = r / 2 / Math.cos(thetaOffset),
      theta2 = theta + thetaOffset;
    var midpointX = r2 * Math.cos(theta2) + parseFloat(start[1]),
      midpointY = r2 * Math.sin(theta2) + parseFloat(start[0]);
    var midpoint = [midpointY, midpointX];

    return new L.curve(["M", start, "Q", midpoint, end], {
      color,
      opacity,
      weight: 7,
      className,
    });
  };

  const getEpoch = (utcSeconds) => {
    var d = new Date(0);
    return d.setUTCSeconds(utcSeconds);
  };

  // time helpers
  function getTimestampDifference(date1, date2) {
    var difference = new Date(date1).getTime() - new Date(date2).getTime() + 1;
    // return Math.floor(difference / 1000);
    return difference;
  }

  const getLocalDateString = (
    date,
    locale = "en",
    options = { dateStyle: "long" }
  ) => {
    const receivedDate = new Date(date);
    return receivedDate.toLocaleDateString(locale, options);
  };

  const getLocalTimeString = (
    date,
    locale = "en",
    options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }
  ) => {
    const receivedDate = new Date(getEpoch(date));
    return receivedDate.toLocaleTimeString(locale, options);
  };

  const getLocalString = (date, locale = "en", options = {}) => {
    const receivedDate = new Date(date);
    return receivedDate.toLocaleString(locale, options);
  };

  const secToHMS = (seconds) => {
    return new Date(seconds * 1000).toISOString().substring(11, 19);
  };

  const validateJson = (json) => {
    try {
      JSON.parse(json);
      return JSON.parse(json);
    } catch (error) {
      return null;
    }
  };

  return {
    getEventShortenType,
    getEventTitle,
    eventMarker,
    startEndMarker,
    routeLine,
    routeCurvedPath,
    getTimestampDifference,
    getLocalDateString,
    getLocalTimeString,
    getLocalString,
    secToHMS,
    validateJson,
  };
})();

export default mapHelper;
